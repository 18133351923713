<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title statement-head" v-if="reuseComponent">
            Statement Report
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card outlined v-if="reuseComponent">
            <v-card-title class="title pb-0">
              <v-flex xs2>
                <v-text-field
                  v-model="form.enroll_code"
                  class="pa-0"
                  label="Enroll Code"
                  placeholder="ST-76001"
                  outlined
                  dense
                />
              </v-flex>
              <v-flex xs4>
                <v-student-search-field
                  :withDeleted="true"
                  @onSelectStudent="selectStudent"
                ></v-student-search-field>
              </v-flex>
              <v-flex xs2>
                <search-button
                  style=""
                  :disabled="!form.enroll_code"
                  permission="billing-report-statement"
                  @action="get()"
                >
                  Search
                </search-button>
              </v-flex>
              <v-flex xs3>
                <print-buttons
                  v-if="form.items.data.length"
                  :downloadUrl="downloadUrl"
                  :passAuthToReport="true"
                  style="float: right"
                  :excel="false"
                  labelExcel="Download Excel"
                  labelPdf="Download Pdf"
                ></print-buttons>
              </v-flex>
              <v-flex xs1>
                <transition
                  name="animate-css-transition"
                  enter-active-class="animated fadeInRight"
                  leave-active-class="animated fadeOutRight"
                >
                  <v-btn
                    v-if="students.length || form.enroll_codelength"
                    fab
                    small
                    @click="
                      (form.enroll_code = ''), (students = []), (form.name = '')
                    "
                    style="z-index: 1"
                    class="error"
                  >
                    <v-icon dark>close</v-icon>
                  </v-btn>
                </transition>
              </v-flex>
            </v-card-title>
          </v-card>
          <br />
          <div
            v-if="form.items.data.length > 0"
            class="data-represent"
            style="margin-top: 8px"
          >
            <div>
              <span class="d-success"></span> &nbsp;
              <small>
                <strong>{{ stats.paid }}</strong> Full Paid</small
              >
            </div>
            <div>
              <span class="d-warning"></span> &nbsp;
              <small
                ><strong>{{ stats.partial }}</strong> Partially Paid</small
              >
            </div>

            <div>
              <span class="d-error"></span> &nbsp;
              <small
                ><strong>{{ stats.due }}</strong> Unpaid</small
              >
            </div>

            <div>
              <span class="d-info"></span> &nbsp;
              <small
                ><strong>{{ form.items.data.length }}</strong>
                Records
              </small>
            </div>
          </div>

          <div
            style="margin-left: 20px; margin-top: 5px; color: #ff5252"
            v-if="advanceOpening > 0 && form.items.data.length"
          >
            <span>Opening Advance Amount : </span>
            <span>{{ advanceOpening.currency() }}</span>
          </div>
          <v-data-table
            :headers="headers"
            :items="form.items.data"
            :server-items-length="form.items.meta.total"
            :loading="loading"
            :options.sync="pagination"
            hide-default-footer
          >
            <template v-slot:item="{ index, item }">
              <tr
                class=""
                style="cursor: pointer"
                @click="viewStatementDetails(item)"
              >
                <td
                  :class="{
                    'text-xs-left': true,
                    'd-alert-row': true,
                    'd-warningd': item.invoice_status === '3',
                    'd-successd': item.invoice_status === '1',
                    'd-errord': item.invoice_status === '0',
                  }"
                >
                  {{ index + 1 }}
                </td>
                <td class="text-xs-left">
                  {{ item.bill_date }}
                </td>
                <td class="text-xs-left">
                  {{ item.via.toUpperCase() }}
                  <!-- <strong>{{(item.via.toUpperCase() === 'CASH' && item.advance_amount ===item.bill_amount) ? `[FULL ADVANCE]` : (item.via.toUpperCase() === 'CASH' && item.advance_amount > 0  ? `[PARTIAL ADVANCE]` : '')}}</strong> -->
                  <div v-if="item.bill_month">
                    <small
                      >({{
                        item.bill_month === "13"
                          ? "Baisakh (13)"
                          : item.bill_month === "14"
                          ? "Jestha (14)"
                          : item.bill_month
                      }})</small
                    >
                  </div>
                </td>
                <td class="text-xs-left">
                  <span v-if="item.via === 'Previous Due'">
                    {{
                      item.direct_paid != 0
                        ? item.direct_paid.currency()
                        : item.bill_amount
                    }}
                    <span
                      v-if="item.direct_paid < item.bill_amount"
                      style="display: block; font-size: 11px; font-weight: bold"
                    >
                      ({{
                        item.bill_amount != 0
                          ? item.bill_amount.currency()
                          : "-"
                      }})
                    </span>
                  </span>
                  <span v-else>{{
                    item.bill_amount != 0 ? item.bill_amount.currency() : "-"
                  }}</span>
                </td>
                <td class="text-xs-left">
                  {{
                    item.paid_amount != 0 ? item.paid_amount.currency() : "-"
                  }}
                </td>
                <td class="text-xs-left">
                  {{
                    item.instant_discount != 0
                      ? item.instant_discount.currency()
                      : "-"
                  }}
                </td>
                <td>{{ item.remarks }}</td>
              </tr>
              <tr
                class="info"
                style="color: white; font-weight: bold"
                v-if="index === form.items.data.length - 1"
              >
                <td></td>
                <td></td>
                <td>Total</td>
                <td>{{ billSum.currency() }}</td>
                <td>{{ totalSum.currency() }}</td>
                <td>{{ instantDiscountSum.currency() }}</td>
                <td></td>
              </tr>
              <tr
                :class="totalDueAdvAmount > 0 ? 'error' : 'success'"
                style="color: white; font-weight: bold"
                v-if="
                  index === form.items.data.length - 1 &&
                  (totalDueAdvAmount > 0 || totalDueAdvAmount !== 0)
                "
              >
                <td
                  style="cursor: pointer"
                  @dblclick="
                    totalDueAdvAmount > 0
                      ? $router.push({
                          name: 'fee-payment',
                          query: {
                            enroll_code: selectedStudent.enroll_code,
                            _s_: selectedStudent.details.section_id,
                            _e_i_: selectedStudent.enroll_id,
                          },
                        })
                      : null
                  "
                  :colspan="headers.length"
                >
                  <span v-if="totalDueAdvAmount > 0">
                    Remaining Due =
                    {{ totalDueAdvAmount.currency() }} (
                    {{ convertNumberToWords(totalDueAdvAmount, true) }}
                    )
                  </span>
                  <span v-else-if="Math.abs(totalDueAdvAmount) !== 0">
                    <span v-if="Math.abs(totalDueAdvAmount) !== 0">
                      Advance =
                      {{ Math.abs(totalDueAdvAmount).currency() }}
                      (
                      {{
                        convertNumberToWords(Math.abs(totalDueAdvAmount), true)
                      }}
                      )
                    </span>
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="detailDialogue" persistent max-width="1000px">
      <v-card>
        <v-card-title
          v-if="selectedDetail"
          :class="selectedDetailAlertType + ' white--text'"
        >
          <span class="title"
            >{{ detailTitle }}
            {{
              detailTitle !== "Invoice Details"
                ? `(Bill No: ${selectedDetail.bill_no})`
                : `(Invoice No: ${selectedDetail.invoice_no})`
            }}</span
          >
        </v-card-title>
        <v-card-text class="pb-1 pt-4">
          <v-flex xs12>
            <alert-message
              v-if="selectedDetail && detailTitle === 'Invoice Details'"
              :extraSmall="true"
              :type="selectedDetailAlertType"
              :title="
                selectedDetailAlertType === 'error'
                  ? 'Not Paid'
                  : (selectedDetailAlertType === 'warning'
                      ? 'Partial Paid'
                      : 'Full Paid') + ` (${selectedDetail.invoice_no})`
              "
              :message="`Invoice was generated on ${selectedDetail.bill_date}(${selectedDetail.bill_month}), Remarks: ${selectedDetail.remarks} `"
            ></alert-message>
            <alert-message
              v-if="selectedDetail && detailTitle !== 'Invoice Details'"
              :extraSmall="true"
              :type="selectedDetailAlertType"
              :title="`Paid: ${selectedDetail.paid_amount.currency()} / Dis: ${selectedDetail.instant_discount.currency()} / Adv: ${selectedDetail.advance_amount.currency()}`"
              :message="`Transaction was created on ${selectedDetail.bill_date}(${selectedDetail.via}), Remarks: ${selectedDetail.remarks} `"
            ></alert-message>
            <table
              v-if="selectedDetail && detailTitle === 'Invoice Details'"
              class="basic_styled_table"
              width="100%"
              cellpadding="5"
              cellspacing="5"
            >
              <tr>
                <!-- <th>Inv No.</th> -->
                <th>Fee Head</th>
                <th>Fee Head Amt.</th>
                <th>Dis. / Sch.</th>
                <th>Total Amt.</th>
                <th>Paid Amt.</th>
                <th>Due Amt.</th>
              </tr>
              <tbody>
                <tr v-for="(item, key) in selectedDetail.details" :key="key">
                  <!-- <td>
                    {{item.invoice_no}}
                  </td> -->
                  <td>{{ item.fee_head }}</td>
                  <td>{{ item.fee_head_amount.currency() }}</td>
                  <td>{{ item.discount }} / {{ item.scholarship }}</td>
                  <td>{{ item.total.currency() }}</td>
                  <td>
                    <strong>{{ item.paid_amount.currency() }}</strong>
                  </td>
                  <td>
                    <strong>{{ item.due_amount.currency() }}</strong>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">Total</td>
                  <td>
                    <strong>{{
                      selectedDetail.details
                        .map((item) => item.total)
                        .reduce((a, b) => a + b, 0)
                        .currency()
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      selectedDetail.details
                        .map((item) => item.paid_amount)
                        .reduce((a, b) => a + b, 0)
                        .currency()
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      selectedDetail.details
                        .map((item) => item.due_amount)
                        .reduce((a, b) => a + b, 0)
                        .currency()
                    }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
            <table
              class="basic_styled_table"
              width="100%"
              v-else-if="selectedDetail && detailTitle !== 'Invoice Details'"
            >
              <thead>
                <tr>
                  <!-- <th>Inv No.</th> -->
                  <th>Invoice No</th>
                  <th>Fee Head</th>
                  <th>Dis. / Sch.</th>
                  <th>Total Amt.</th>
                  <th>Paid Amt.</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, key) in selectedDetail.details" :key="key">
                  <td>{{ item.invoice_no }}</td>
                  <td>
                    {{
                      item.invoice_detail ? item.invoice_detail.fee_head : "-"
                    }}
                  </td>
                  <td>
                    {{
                      item.invoice_detail ? item.invoice_detail.discount : "-"
                    }}
                    /
                    {{
                      item.invoice_detail
                        ? item.invoice_detail.scholarship
                        : "-"
                    }}
                  </td>
                  <td>
                    {{
                      item.invoice_detail
                        ? item.invoice_detail.total.currency()
                        : "-"
                    }}
                  </td>
                  <td>{{ item.amount ? item.amount.currency() : "-" }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">Total</td>
                  <td>
                    <strong>{{
                      selectedDetail.details
                        .map((item) =>
                          item.invoice_detail ? item.invoice_detail.total : 0
                        )
                        .reduce((a, b) => a + b, 0)
                        .currency()
                    }}</strong>
                  </td>
                  <td>
                    <strong>{{
                      selectedDetail.details
                        .map((item) => item.amount)
                        .reduce((a, b) => a + b, 0)
                        .currency()
                    }}</strong>
                  </td>
                </tr>
              </tfoot>
            </table>
          </v-flex>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" text @click="detailDialogue = false"
            >Close</v-btn
          >
          <v-btn color="success" @click="printInvoiceOrReceipt" text>{{
            detailTitle === "Invoice Details"
              ? "Print Invoice"
              : "Print Receipt"
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import { mapState } from "vuex";
import { convertNumberToWords } from "@/library/helpers";
import Mixins from "@/library/Mixins";
import Form from "@/library/Form";
import AlertMessage from "../../../../components/AlertMessage.vue";
import { redirectDownloadUrl } from "../../../../library/helpers";

export default {
  components: { AlertMessage },
  mixins: [Mixins],
  props: {
    reuseComponent: {
      default: true,
    },
    enrollCodeProp: {
      default: null,
    },
  },
  data: () => ({
    selectedDetail: null,
    selectedDetailAlertType: false,
    form: new Form(
      {
        enroll_code: "",
        name: "",
      },
      "/api/report/statement"
    ),
    pagination: {
      itemsPerPage: 100,
    },
    detailDialogue: false,
    detailTitle: "",
    loading: false,
    totalSum: 0,
    billSum: 0,
    instantDiscountSum: 0,
    search: null,
    downloadUrl: "",
    students: [],
    studentLoading: false,
    advanceOpening: 0,
    totalDueAdvAmount: 0,
    stats: {
      paid: 0,
      partial: 0,
      due: 0,
    },
    headers: [
      {
        text: "S. No",
        align: "left",
        value: "id",
        sortable: false,
        width: 70,
      },
      {
        text: "Date",
        align: "left",
        value: "bill_date",
        sortable: false,
      },
      { text: "Via", align: "left", value: "via", sortable: false },
      {
        text: "Bill Amount",
        align: "left",
        value: "bill_amount",
        sortable: false,
        width: 140,
      },
      {
        text: "Paid Amount",
        align: "left",
        value: "paid_amount",
        sortable: false,
        width: 140,
      },
      {
        text: "Instant Discount",
        align: "left",
        value: "instant_discount",
        sortable: false,
        width: 140,
      },
      {
        text: "Remarks",
        align: "left",
        value: "remarks",
        sortable: false,
        width: 200,
      },
    ],
    selectedStudent: {},
  }),

  computed: {
    ...mapState(["batch"]),
  },

  mounted() {
    if (this.enrollCodeProp) {
      this.form.enroll_code = this.enrollCodeProp;
    }
    this.get();
  },
  watch: {
    "form.enroll_code": function (v) {
      this.form.items.data = [];
      this.form.name = "";
      if (v && v.length > 7) {
        // this.get();
      }
    },

    "form.name": function (value) {
      this.students.filter((res) => {
        if (res.value === value) {
          this.form.enroll_code = res.enrollCode;
        }
      });
    },
  },

  methods: {
    convertNumberToWords,
    selectStudent(student) {
      if (student) {
        this.selectedStudent = student;
        this.form.enroll_code = student.enroll_code;
      }
    },
    printInvoiceOrReceipt() {
      if (this.selectedDetail.bill_no === null) {
        const {
          inv_sequence,
          bill_date,
          bill_month_numeric,
          billing,
          enroll_code,
          enroll_id,
          invoice_no,
        } = this.selectedDetail;

        redirectDownloadUrl({
          uri: "/print/bill/single",
          queryString: `billing=${billing ? billing : ""}&billMonth=${
            bill_month_numeric ? bill_month_numeric : ""
          }&enrollCode=${enroll_code}&enroll=${enroll_id}&billDate=${bill_date}&inv_sequence=${inv_sequence}&invoiceNo=${invoice_no}&showPaidDue=${true}`,
        });
      } else {
        const { bill_no, enroll_id } = this.selectedDetail;
        redirectDownloadUrl({
          uri: "/print/bill/fee-receipt",
          queryString: `printer_type=dot-matrix&receipt=${bill_no}&enrollId=${enroll_id}`,
        });
      }
    },
    viewStatementDetails(item) {
      if (item.bill_no === null && item.via != "Previous Due") {
        this.detailDialogue = true;

        this.detailTitle = "Invoice Details";
        this.selectedDetail = item;
        this.selectedDetailAlertType =
          item.invoice_status === "1"
            ? "success"
            : item.invoice_status === "3"
            ? "warning"
            : "error";
      } else if (item.via != "Previous Due") {
        this.detailTitle = "Transaction Details";
        this.selectedDetailAlertType = "primary";
        this.$rest
          .get(`/api/report/transaction-detail/${item.bill_no}`)
          .then((res) => {
            this.detailDialogue = true;
            this.selectedDetail = {
              ...item,
              details: res.data.data,
            };
          });
      }
    },
    get(params) {
      if (this.form.enroll_code) {
        let extraParams = "&enrollCode=" + this.form.enroll_code;

        let query = [null, undefined].includes(params)
          ? this.queryString(extraParams)
          : params;
        this.loading = true;
        this.$rest
          .get("/api/report/statement-old" + query)
          .then(({ data }) => {
            let $this = this;
            $this.billSum = 0;
            $this.instantDiscountSum = 0;
            $this.totalSum = 0;
            $this.totalDueAdvAmount = 0;
            $this.advanceOpening = data.advance_opening;
            this.downloadUrl = data.download_url;
            this.stats.due = 0;
            this.stats.partial = 0;
            this.stats.paid = 0;

            let pvDueRecords = data.data.map((item) => {
              if (item.invoice_status === "0") {
                this.stats.due++;
              }
              if (item.invoice_status === "1") {
                this.stats.paid++;
              }
              if (item.invoice_status === "3") {
                this.stats.partial++;
              }
              $this.billSum += parseFloat(item.bill_amount);
              $this.totalSum += parseFloat(item.paid_amount);
              $this.instantDiscountSum += parseFloat(item.instant_discount);
              return item;
            });

            $this.billSum += data.previous_due
              ? data.previous_due.amount -
                data.previous_due.actual_paid +
                data.previous_due.direct_paid
              : 0;

            $this.totalDueAdvAmount =
              $this.billSum -
              $this.totalSum -
              $this.instantDiscountSum -
              $this.advanceOpening;

            this.students = [
              {
                value: data.student.enroll_code,
                enrollCode: data.student.enroll_code,
                text:
                  data.student.name +
                  " (" +
                  data.student.grade +
                  "-" +
                  data.student.section +
                  ") - " +
                  data.student.roll,
              },
            ];

            this.form.name = data.student.enroll_code;
            this.form.items = data;
            this.form.items.data =
              data.previous_due && data.previous_due.direct_paid > 0
                ? [
                    this.constructPreviousDueRecord(data.previous_due),
                    ...pvDueRecords,
                  ]
                : pvDueRecords;

            if (pvDueRecords.length < 1 && data.previous_due) {
              this.form.items.data = [
                this.constructPreviousDueRecord(data.previous_due),
              ];
              this.form.items.meta.total = 1;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },

    constructPreviousDueRecord(previous_due) {
      return {
        advance_amount: "",
        bill_amount: previous_due.amount,
        direct_paid: previous_due.direct_paid,
        bill_date: previous_due.created_at,
        bill_month: "",
        bill_month_numeric: "",
        bill_no: "",
        billing: "",
        details: "",
        enroll_code: "",
        enroll_id: "",
        instant_discount: "",
        inv_sequence: "",
        invoice_id: "",
        invoice_no: "",
        invoice_status: "",
        paid_amount: "",
        remarks: "",
        via: "Previous Due",
      };
    },

    print(item) {
      let url =
        this.printUrl +
        "?receipt=" +
        item.bill_no +
        "&enrollId=" +
        item.enroll_id;
      window.open(url);
    },
  },
};
</script>